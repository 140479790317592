import React, { Component } from 'react';

export class Admin extends Component {
  render() {
    return (
      <div>
        checking
      </div>
    );
  }
}