import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter} from 'react-router-dom';
import RootReducer from './store/reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { reduxFirestore, getFirestore} from 'redux-firestore';
import firebaseConfig from './config/config';
import thunk from 'redux-thunk';


const store = createStore(RootReducer, 
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirestore })),
    reduxFirestore(firebaseConfig)
  )
);

const app = (
  <Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();